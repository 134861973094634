.tier-wrapper {
	/* background-color: orange; */
	padding: 1.25rem;
	/* border-radius: 0.75rem; */
	/* border: 3px solid black; */
	transition: 0.3s;
	background-color: rgb(248, 249, 250);
	border-bottom: 1px solid rgb(242, 244, 247);
	border-top: 1px solid rgb(242, 244, 247);
	/* box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px; */
	border: 1px solid lightgray;
	border-radius: 0.75rem;
	background-color: white;
	position: relative;
}

.tier-wrapper.free-tier {
	background-color: #1c2536;
	color: white;
}

.tier-wrapper.disabled:hover {
	box-shadow: none;
}

.tier-wrapper:hover {
	box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.MuiButtonBase-root.enterprise {
	background-color: #1c2536;
}

.MuiButtonBase-root.Mui-disabled.free-tier {
	background-color: #e2e3e566;
}

.price-tile:hover {
	box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}