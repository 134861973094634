.bar {
	width: 2.5px;
	height: 20px;
	background: black;
	display: inline-block;
	border-radius: 2px;
	margin-left: -2px;
	margin-bottom: -5px;
}

.pulse {
	animation: pulse-animation 1s infinite alternate;
}

@keyframes pulse-animation {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.2);
	}
}