.div {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2rem;
	height: 2rem;
}
.dotFront {
	animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	vertical-align: middle;
	position: absolute;
	width: 2rem;
	height: 2rem;
	border-radius: 9999px;
	background-color: rgb(255, 228, 230);
}

.dotBack {
	animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
	vertical-align: middle;
	position: absolute;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 9999px;
	background-color: rgb(244, 63, 94);
}

@keyframes pulseRing {
	0% {
		transform: scale(0.5);
	}
	80%,
	100% {
		opacity: 0;
	}
}

@keyframes pulseDot {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
}
