.loader-centering {
    position: absolute;
    top: 50%;
    left: 50%;
}

.loader {
	height: 5rem;
	width: 5rem;

	margin: auto;

	animation: spin 2s infinite linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
